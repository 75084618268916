import { addDays } from "date-fns";
import React, { useEffect, useRef, useState, useContext } from "react";
import { isMobile } from "react-device-detect";
import { DateRange, Range } from "react-date-range";
import { Link, useNavigate, useLocation } from "react-router-dom";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { Button } from "react-bootstrap";
import Api from "../../services/api.service";
import { CONSTANTS } from "../../utils/Constants";
import { PadNumber, stringToUrlString } from "../../utils/helpers";
import RoomGuestSelectorModal from "../../container/modals/RoomGuestSelectorModal";
import { HotelContext } from "../../Reducers/HotelView/HotelContext";
import { UPDATE_ROOM_DATA } from "../../Reducers/HotelView/HotelReducer";

const SearchBar: React.FC = () => {
  const [datePicker, setdatePicker] = useState(false);
  const dateKey = useRef<DateRange | null>(null);
  const [checkindate, setcheckindate] = useState<Date>(new Date());
  const [checkoutdate, setcheckoutdate] = useState<Date>(
    addDays(new Date(), 1)
  );
  const [query, setquery] = useState<string>("");
  const [searchResult, setSearchResult] = useState<any[]>([]);
  const [cities, setcities] = useState<any[]>([]);
  const [selectedCity, setSelectedCity] = useState<any | null>(null);
  const [searchType, setSearchType] = useState<string>("");
  const [searchLoading, setSearchLoading] = useState<boolean>(false);
  const [locationError, setLocationError] = useState<string>("");
  const location = useLocation();
  const history = useNavigate();
  const roomRef = useRef<any>();

  const hotelContext = useContext(HotelContext);

  const [ranges, setranges] = useState<Range[]>([
    {
      startDate: new Date(),
      endDate: addDays(new Date(), 1),
      key: "selection",
    },
  ]);

  type RangeKeyDict = {
    selection: Range;
  };

  const saveCheckinCheckOut = (item: RangeKeyDict) => {
    const dateObj = dateKey.current as any;
    if (dateObj && "state" in dateObj) {
      const focus = dateObj.state.focusedRange || [0, 0];
      if (focus[0] === 0 && focus[1] === 1) {
        let checkin = moment(item.selection.startDate).format("YYYY-MM-DD");
        let checkout = moment(item.selection.endDate).format("YYYY-MM-DD");
        changeCheckinCheckout(checkin, checkout);
      }
    }
  };

  const changeCheckinCheckout = (checkin: string, checkout: string) => {
    setcheckindate(new Date(checkin));
    setcheckoutdate(new Date(checkout));
    let payload = {
      checkInDate: checkin,
      checkOutDate: checkout,
    };
    hotelContext?.hotelDispatch({
      type: UPDATE_ROOM_DATA,
      payload: payload,
    });
  };

  const search = (queryPrams: string) => {
    setSearchLoading(true);
    const formdata = new FormData();
    formdata.append("query", queryPrams);
    Api.post("/HotelSearch/search_hotels", formdata).then((res) => {
      let data = res.data;
      console.log("Res", data);
      if (res.data["status"] === "success") {
        setSearchResult(data.data);
        setcities(data.cities);
        setSearchLoading(true);
      } else {
        setSearchLoading(true);
      }
    });
  };

  useEffect(() => {
    if (roomRef.current != null) {
      setSearchOptions();
    }
  }, [location.search, roomRef]);

  const setSearchOptions = () => {
    const roomOb = roomRef.current;
    const params = new URLSearchParams(location.search);
    params.get("type") && setSearchType(params.get("type") as string);
    params.get("query") && setquery(params.get("query") as string);
    params.get("checkin") &&
      changeCheckinCheckout(
        params.get("checkin") as string,
        params.get("checkout") as string
      );
    params.get("checkin") &&
      setranges([
        {
          startDate: new Date(params.get("checkin") as string),
          endDate: new Date(params.get("checkout") as string),
          key: "selection",
        },
      ]);
    params.get("rooms") &&
      roomOb.roomGuestUpdate(
        params.get("rooms") as string,
        params.get("guests") as string
      );
  };

  const searchClick = () => {
    if (searchType === "") {
      setLocationError("Please select a destination from the list");
      return;
    } else {
      setLocationError("");
    }
    const roomOb = roomRef.current;
    const params = new URLSearchParams();
    params.set("type", searchType);
    params.set("query", query);
    params.set("checkin", checkindate.toISOString());
    params.set("checkout", checkoutdate.toISOString());
    params.set("rooms", roomOb.numofroom);
    params.set("guests", roomOb.numofguest);
    if (selectedCity != null) {
      params.set("city_id", selectedCity.city_id);
    }
    const urlString = `/search?${params.toString()}`;
    history(urlString);
  };

  return (
    <>
      <div className="home-search-wrapper">
        <div className="Seach-Input-Block">
          <div className="search-input tooltipCustome">
            <input
              name="search"
              className="home-search-input"
              type="text"
              placeholder="Search city"
              value={query}
              onChange={(e) => {
                if (e.target.value.length > 2) {
                  search(e.target.value);
                } else {
                  setSearchResult([]);
                  setcities([]);
                }
                setSearchType("");
                setLocationError("");
                setquery(e.target.value);
              }}
            />
            {locationError !== "" && (
              <div className={"tooltiptext"}>{locationError}</div>
            )}
          </div>
          {query.length > 0 ? (
            <div
              onClick={() => {
                setquery("");
              }}
              style={{ padding: 18, fontSize: "1.2em", cursor: "pointer" }}
            >
              <FontAwesomeIcon className="text-danger" icon={faTimes} />
            </div>
          ) : (
            <Link to="/search/nearby" className="search-location d-flex">
              <input type="button" name="" value="Near me" />
            </Link>
          )}
          {(searchResult.length !== 0 || cities.length !== 0) && (
            <div
              className={`SearchResultDropDown ${
                query.length > 1 && `d-block`
              }`}
            >
              {cities.length !== 0 && (
                <>
                  {cities.map((city, index) => {
                    return (
                      <Button
                        key={index.toString()}
                        onClick={() => {
                          setSearchType("city");
                          setLocationError("");
                          setSelectedCity(city);
                          setquery(city.city_name);
                          setSearchResult([]);
                          setcities([]);
                        }}
                        variant={"link"}
                        className={"btn-block"}
                        // to={`/hotel-by-city/${city.city_id}`}
                      >
                        <div className="">
                          <h1 className="Searchdata-text">
                            <img
                              alt="img"
                              src={CONSTANTS.LOGO}
                              className={"logoBrandingImage"}
                            />
                            {city.city_name}
                          </h1>
                        </div>
                      </Button>
                    );
                  })}
                </>
              )}
              {searchResult.length !== 0 && (
                <>
                  {searchResult.map((room, index) => {
                    return (
                      <Link
                        to={`/hotel/${stringToUrlString(room.hotel_name)}/${
                          room.id
                        }/${"0"}`}
                        key={index.toFixed()}
                      >
                        <div className="Search-List">
                          <h1 className="Searchdata-text">
                            <img
                              alt="img"
                              src={CONSTANTS.LOGO}
                              className={"logoBrandingImage"}
                            />
                            {PadNumber(room.id, 4)} {room.hotel_name}
                          </h1>
                        </div>
                      </Link>
                    );
                  })}
                </>
              )}
            </div>
          )}
        </div>
        <div className="datePicker">
          <p
            style={{ padding: 20 }}
            onClick={() => {
              setdatePicker(!datePicker);
            }}
            className="DateGuestText"
          >{`${moment(checkindate).format("ddd, D MMM  ")} - ${moment(
            checkoutdate
          ).format("ddd, D MMM  ")}`}</p>
          {datePicker && (
            <DateRange
              ref={dateKey}
              minDate={new Date()}
              className="DateRangePicker"
              onChange={(item) => {
                setranges([item.selection]);
                saveCheckinCheckOut({ selection: item });
              }}
              moveRangeOnFirstSelection={false}
              ranges={ranges}
              months={isMobile ? 1 : 2}
              direction="horizontal"
              dragSelectionEnabled={true}
              showPreview={true}
              showDateDisplay={false}
              onRangeFocusChange={(focus) => {
                if (focus[0] === 0 && focus[1] === 0) {
                  setdatePicker(false);
                }
              }}
            />
          )}
        </div>
        <div className="GuestRoomPicker">
          <RoomGuestSelectorModal ref={roomRef} />
        </div>
        <Button onClick={searchClick} className="search-button">
          Search
        </Button>
        {/* <Link to="/search" className="search-button">
          <input type="button" value="Search" name="" />
        </Link> */}
      </div>
    </>
  );
};

export default SearchBar;
