export const stringToUrlString = (input: string): string => {
  let urlString = input.replace(/\s+/g, "-");
  return urlString.toLowerCase();
};

export const GETPARSEDATA = (strDate: string): string => {
  let date = new Date(strDate);

  if (isNaN(date.getTime())) {
    throw new Error("Invalid date format. Expected format: YYYY-MM-DD");
  }

  let dd: any = date.getDate();
  let mm: any = date.getMonth() + 1; // January is 0!

  let yyyy: any = date.getFullYear();
  if (dd < 10) {
    dd = "0" + dd;
  }
  if (mm < 10) {
    mm = "0" + mm;
  }
  return dd + "-" + mm + "-" + yyyy;
};

export const GETPARSEDATA_YY = (strDate: string): string => {
  let date = new Date(strDate);

  if (isNaN(date.getTime())) {
    throw new Error("Invalid date format. Expected format: YYYY-MM-DD");
  }

  let dd: any = date.getDate();
  let mm: any = date.getMonth() + 1; // January is 0!

  let yyyy: any = date.getFullYear();
  if (dd < 10) {
    dd = "0" + dd;
  }
  if (mm < 10) {
    mm = "0" + mm;
  }
  return yyyy + "-" + mm + "-" + dd;
};

export const amenitiesFind = (
  findAmenityID: any,
  amenitiesArray: any[]
): number => {
  let find = amenitiesArray.find((amenity) => {
    return amenity === findAmenityID;
  });
  return find ? 1 : 0;
};

export const PoliciesFind = (
  findPolicyID: any,
  PoliciesArray: any[]
): number => {
  let find = PoliciesArray.find((policy) => {
    return policy === findPolicyID;
  });
  return find ? 1 : 0;
};

interface GetTotalRoomsArgs {
  roomCategories: any[];
  floorRooms: any[];
  roomCategory?: string;
}

export const GET_TOTAL_ROOMS = ({
  roomCategories,
  floorRooms,
  roomCategory,
}: GetTotalRoomsArgs): { totalRooms: number; totalFilled: number } => {
  let totalRooms = 0;
  let totalFilled = 0;

  floorRooms.forEach((floor) => {
    let floorChecked = false;
    if (roomCategory) {
      let checked = floor["cat_checked_" + roomCategory];
      if (checked !== undefined && checked) {
        floorChecked = true;
      }
    } else {
      roomCategories.forEach((category) => {
        let checked = floor["cat_checked_" + category.id];
        if (checked !== undefined && checked) {
          floorChecked = true;
        }
      });
    }
    // Enabled floors
    if (floorChecked) {
      floor.rooms.forEach((room: any) => {
        if (roomCategory) {
          if (room.room_category === roomCategory) {
            // Increase total room count
            totalRooms++;
            if (room.room_filled) {
              // Increase filled count
              totalFilled++;
            }
          }
        } else {
          // Increase total room count
          totalRooms++;
          if (room.room_filled) {
            // Increase filled count
            totalFilled++;
          }
        }
      });
    }
  });

  return {
    totalRooms,
    totalFilled,
  };
};

export const PadNumber = (num: number, size: number): string => {
  let s = num.toString();
  while (s.length < size) s = "0" + s;
  return s;
};

export const removeHtml = (str: string | null | undefined): string | false => {
  if (!str) return false;
  return str.toString().replace(/(<([^>]+)>)/gi, "");
};
