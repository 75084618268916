import React, { useState, useEffect } from "react";
import Api from "../../services/api.service";
import { amenitiesFind } from "../../utils/helpers";
import { CONSTANTS } from "../../utils/Constants";

const resultLength = 5;
const maxResults = 10;

interface Amenity {
  id: string;
  icon: string;
  amenity: string;
}

interface Props {
  roomAmenity: any;
}

const AmenitiesWithFilter: React.FC<Props> = (props) => {
  const [loading, setLoading] = useState(true);
  const [amenities, setAmenities] = useState<Amenity[]>([]);
  const [showMore, setShowMore] = useState(false);
  const [allAmenities, setAllAmenities] = useState<Amenity[]>([]);

  useEffect(() => {
    if (allAmenities.length > resultLength) {
      setAmenities(allAmenities.slice(0, resultLength));
    } else {
      setAmenities(allAmenities.slice(0, maxResults));
    }
  }, [allAmenities]);

  const showMoreLess = (status: boolean) => {
    if (status) {
      setAmenities(allAmenities.slice(0, maxResults));
    } else {
      setAmenities(allAmenities.slice(0, resultLength));
    }
    setShowMore(status);
  };

  useEffect(() => {
    setLoading(true);
    const initialLoad = async () => {
      await getAmenities();
    };
    initialLoad();
  }, []);

  const getAmenities = async () => {
    setAmenities([]);
    var formData = new FormData();
    try {
      const res = await Api.post("amenities", formData);
      var data = res.data;
      if (data.status === "success") {
        const ami = data.data;
        var amiArray: Amenity[] = [];
        ami.forEach((item: Amenity) => {
          var find = amenitiesFind(item.id, JSON.parse(props.roomAmenity));
          if (find > 0) {
            amiArray.push(item);
          }
        });
        setAmenities(amiArray);
        setAllAmenities(amiArray);
      }
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <>
      <div className="all-amenities">
        <div className="row">
          {amenities.map((item, index) => (
            <div className="col-4" key={index}>
              <div className="d-flex align-items-center my-3 animityRow">
                <img
                  style={{ width: 22, height: 22 }}
                  src={`${CONSTANTS.IMAGE_BASE_URL}/amenity/${item.icon}`}
                />
                <p className="ml-2">{item.amenity}</p>
              </div>
            </div>
          ))}
        </div>
        {allAmenities.length > resultLength && (
          <div
            onClick={() => showMoreLess(!showMore)}
            className={"btn text-left"}
            style={{
              fontWeight: "700",
              paddingTop: 10,
              fontSize: 15,
              color: "#1E5698",
            }}
          >
            {showMore ? "- show less" : "+ show more"}
          </div>
        )}
      </div>
    </>
  );
};

export default AmenitiesWithFilter;
