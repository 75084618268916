import defaultRoomImage from "../assets/images/default_property.png";
import logo from "../assets/images/blue_logo.png";

export const CONSTANTS = {
  IMAGE_BASE_URL: `${process.env.REACT_APP_BASE_URL}uploads/images` as string,
  DAFAULT_ROOM_IMAGE: defaultRoomImage as string,
  LOGO: logo as string,
  PRIMARY_COLOR: "#1E5698" as string,
  BASE_URL: "https://silverstays.in" as string,
  // BASE_URL: "http://localhost:3000" as string, // Commented out since it's not currently used
};

// Optionally, define a type for CONSTANTS if needed
export type ConstantsType = typeof CONSTANTS;
